import { useEffect, useState } from 'react';
import './App.css';
import { SRLWrapper } from "simple-react-lightbox";

const images = [
  {id: '25',imageName: 'pigs.jpg', tag:'paint', detail:'Portfolio work.'},
  {id: '26',imageName: 'wolf.jpg', tag:'vector', detail:'Portfolio work.'},
  {id: '15',imageName: 'kfc.jpg', tag:'cartoon', detail:'Portfolio work.'},
  {id: '3',imageName: 'bomb.jpg', tag:'photoshop', detail:'Digital image manipulation.'},
  {id: '4',imageName: 'box.jpg', tag:'paint', detail:'Box art for a boardgame.'},
  {id: '5',imageName: 'cards.jpg', tag:'comic', detail:'Comic book art explaining a boardgame.'},
  {id: '8',imageName: 'cricket.jpg', tag:'paint', detail:'Portfolio work.'},
  {id: '9',imageName: 'dragon.jpg', tag:'photoshop', detail:'Digital image manipulation.'},
  {id: '10',imageName: 'ducks.jpg', tag:'vector', detail:'Portfolio work.'},
  {id: '11',imageName: 'elf.jpg', tag:'vector', detail:'Portfolio work.'},
  {id: '12',imageName: 'flowers.jpg', tag:'paint', detail:'Portfolio work.'},
  {id: '13',imageName: 'hero.jpg', tag:'paint', detail:'Portfolio work.'},
  {id: '14',imageName: 'john.jpg', tag:'cartoon', detail:'Portfolio work.'},
  {id: '16',imageName: 'kim.jpg', tag:'cartoon', detail:'Portfolio work.'},
  {id: '17',imageName: 'kitten.jpg', tag:'vector', detail:'Portfolio work.'},
  {id: '2',imageName: 'banner.jpg', tag:'design', detail:'A banner for an app I developed.'},
  {id: '7',imageName: 'city.jpg', tag:'comic', detail:'Comic book art.'},
  {id: '18',imageName: 'lady.jpg', tag:'comic', detail:'Comic book art.'},
  {id: '21',imageName: 'monster.jpg', tag:'comic', detail:'Comic book art.'},
  {id: '19',imageName: 'logo.jpg', tag:'design', detail:'A logo for a sports club.'},
  {id: '20',imageName: 'mma.jpg', tag:'design', detail:'A logo for a sports club.'},
  {id: '23',imageName: 'mum.jpg', tag:'paint', detail:'Portfolio work.'},
  {id: '24',imageName: 'panda.jpg', tag:'paint', detail:'Portfolio work.'},
  {id: '27',imageName: 'orc.jpg', tag:'paint', detail:'Digital Model.'},
  {id: '28',imageName: 'shark.jpg', tag:'paint', detail:'Digital Model.'},
  {id: '22',imageName: 'monsters.jpg', tag:'comic', detail:'Comic book art explaining a boardgame.'}

];

const options = {
  settings: {
    overlayColor: 'black'
  },
  buttons: {
    backgroundColor: 'white',
    iconColor: 'black'
  }
};

function App() {
  const [tag,setTag] = useState('all');
  const [filteredImages, setFilteredImages] = useState([]);
  useEffect(() => {
    tag === 'all' ? setFilteredImages(images) : setFilteredImages(images.filter( image => image.tag === tag));
  },[tag])
  return (
    <div className="App">
      <div className="text-container">
        <div className="title"><h1>Tom's Image Gallery</h1></div>
        <div className="bio"><p>Hello, I am a software developer with an interest in art and illustration. 
        While working on improving my knowledge of React I decided to create a gallery of images I have created over the years. </p></div>
      </div>
      <div className="tags">
        <TagButton name="all" handleSetTag={setTag} tagActive={tag=== 'all' ? true : false}/>
        <TagButton name="vector" handleSetTag={setTag} tagActive={tag=== 'vector' ? true : false}/>
        <TagButton name="paint" handleSetTag={setTag} tagActive={tag=== 'paint' ? true : false}/>
        <TagButton name="comic" handleSetTag={setTag} tagActive={tag=== 'comic' ? true : false}/>
        <TagButton name="cartoon" handleSetTag={setTag} tagActive={tag=== 'cartoon' ? true : false}/>
        <TagButton name="photoshop" handleSetTag={setTag} tagActive={tag=== 'photoshop' ? true : false}/>
      </div>
      <SRLWrapper options={options}>
      <div className='container'>{filteredImages.map(image => 
        <div key={image.id} className="card">
          <div className="image-card">
            <a href= {`/images/${image.imageName}`}>
              <img className="image" src={`/images/${image.imageName}`} alt={image.imageName}/>
            </a>
          </div>
          <div ><p>{image.detail}</p></div>
        </div>)}
      </div>
      </SRLWrapper>
    </div>
  );
}

const TagButton = ({name, handleSetTag, tagActive}) => {
  return <button className={`tag-btn ${ tagActive ? 'active' : null}`} onClick={() => handleSetTag(name)}>{name.toUpperCase()}</button>
};

export default App;
